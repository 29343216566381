import { useContext } from 'react';
import { Menu, Icon } from 'semantic-ui-react'
import { useLocation, useNavigate } from 'react-router-dom'
import { userLogoutRequest } from '../api/backend';
import { UserContext } from './userContext';
import { Link } from 'react-router-dom';


export default function Navigation(properties) {

    const { clearSession } = properties;
    const navigate = useNavigate();

    const location = useLocation();
    const sessionUser = useContext(UserContext).data;
    const setSentinel = useContext(UserContext).setSentinel;
    const isAdmin = sessionUser && sessionUser?.admin;
    const isUser = sessionUser && sessionUser?.admin == false && sessionUser?.accountant == false
    const isAccountant = sessionUser && sessionUser?.admin == false && sessionUser?.accountant == true
    const claimsPayableProcedure = sessionUser && sessionUser?.admin == false && sessionUser?.claimsPayableProcedure == true;

    const handleNavigation = (path) => {
        navigate(path);
    }

    const handleSubmitLogout = async (e) => {
        await userLogoutRequest();
        handleNavigation('/login');
        setSentinel(Symbol());
        clearSession();
    }

    return (
        <Menu size='large' stackable>
            <div className="ui container">
                <Menu.Item>
                    <img className="ui" src="/maesstad.png" alt="Logo Maesstad" />
                </Menu.Item>

                {(isAccountant || isUser) && <Menu.Item
                    active={location.pathname == "/"}
                    onClick={() => handleNavigation("/")}
                >
                    <Icon name="home" />
                    Home
                </Menu.Item>}

                <Menu.Item
                    name="Cetificates"
                    onClick={() => handleNavigation("/cargo-certificates")}
                    active={location.pathname == "/cargo-certificates"}
                >
                    <Icon name='file' />
                    Certificates
                </Menu.Item>

                {isAdmin &&
                    <>
                        <Menu.Item
                            name="Users"
                            onClick={() => handleNavigation("/users")}
                            active={location.pathname == "/users"}
                        >
                            <Icon name='users' />
                            Users
                        </Menu.Item>
                        <Menu.Item
                            name="Exclude Countriess"
                            onClick={() => handleNavigation("/exclude-countries")}
                            active={location.pathname == "/exclude-countries"}
                        >
                            <Icon name='file' />
                            Exclude Countries
                        </Menu.Item>
                        <Menu.Item
                            name="Network Affiliations"
                            onClick={() => handleNavigation("/network-affiliations")}
                            active={location.pathname == "/network-affiliations"}
                        >
                            <Icon name='user' />
                            Network Affiliations
                        </Menu.Item>
                    </>

                }

                {(isUser || isAccountant) &&
                    <>
                        <Menu.Item
                            name="Profile"
                            onClick={() => handleNavigation(`/profile/${sessionUser.id}`)}
                            active={location.pathname == `/profile/${sessionUser.id}`}
                        >
                            <Icon name='user' />
                            Profile
                        </Menu.Item>
                        <Menu.Item
                            name="Interest Rates"
                            onClick={() => handleNavigation("/interest-rates")}
                            active={location.pathname == "/interest-rates"}
                        >
                            <Icon name='grid layout' />
                            Interest Rates
                        </Menu.Item>
                    </>
                }

                {(isUser || isAccountant) && claimsPayableProcedure &&
                    <Menu.Item
                        name="Claims payable procedure"
                        onClick={() => handleNavigation("/claims-payable-procedure")}
                        active={location.pathname == "/claims-payable-procedure"}
                    >
                        <svg style={{ transform: "translateY(-4px)" }} height='32px' width='32px' fill="#000000" version="1.1" x="0px" y="0px" viewBox="0 0 96 96" space="preserve"><path markerStart="none" markerEnd="none" d="M22.5,79.5c-1.1,0-2,0.2-2.8,0.6c-0.4,0.2-0.9,0.5-1.3,0.8c-0.5,0.3-0.9,0.6-1.5,0.9  c-2.2,1.4-5,2.8-9.7,2.8l0-2.7c4.1,0,6.3-1.2,8.2-2.4c1.1-0.7,2-1.3,3.1-1.8c1.2-0.6,2.5-0.9,4.1-0.9c1.5,0,2.8,0.4,4,1  c1.2,0.5,2.3,1.2,3.4,1.8c1,0.6,2,1.2,3,1.6l-18-23.6l5.5-2.2l12.1-11.9l3.7,4.9l10.5-8l-2.1-2.8l7.6-5.8l5.4,7.1l6.4-2.1l3.5,4.6  l-5.1,3.9l1.8,2.4L42.7,64.1l9.7,12.7c0.8,0.1,1.5,0.2,2.2,0.5l-10-13.1l2.4-1.8l13.7,18.1c1.6,0.8,3.1,1.4,4.8,1.4  c4.1,0,6.3-1.2,8.2-2.4c1.1-0.7,2-1.3,3.1-1.8c1.2-0.6,2.5-0.9,4.1-0.9c1.3,0,2.6,0.4,3.7,0.8c1.1,0.5,2.2,1.1,3.2,1.7l-1.4,2.4  c-2-1.2-3.7-2.1-5.5-2.1c-1.1,0-2,0.2-2.8,0.6c-0.4,0.2-0.9,0.5-1.3,0.8c-0.5,0.3-0.9,0.6-1.5,0.9c-2.2,1.4-5,2.8-9.7,2.8  c-5.9,0-9.5-5.1-13.9-5.1c-1.1,0-2,0.2-2.8,0.6c-0.4,0.2-0.9,0.5-1.3,0.8c-0.5,0.3-0.9,0.6-1.5,0.9c-2.2,1.4-5,2.8-9.7,2.8  C30.4,84.7,26.9,79.6,22.5,79.5z M47.7,77.7c0.7-0.3,1.5-0.6,2.4-0.8l-1.2-1.6l-2.4,1.8l0.6,0.8C47.3,77.9,47.5,77.8,47.7,77.7z   M44.7,74.8l2.4-1.8l-1.7-2.2L43,72.5L44.7,74.8z M41.3,70.3l2.4-1.8L42,66.2L39.6,68L41.3,70.3z M41.8,62.9l16.5-12.5l-1.6-2  l2.7-2.1l1.6,2l1.4-1.1L52,33.8l-5.2,4l2.1,2.8l-11.7,8.9l1.8,2.3l-1.6,5.3L41.8,62.9z M43,56.9l-1.1-1.5l1.7-1.3l1.1,1.5L43,56.9z   M47.2,53.7l-1.1-1.5l1.7-1.3l1.1,1.5L47.2,53.7z M50.9,50.9l-1.1-1.5l1.7-1.3l1.1,1.5L50.9,50.9z M55.7,47.1l-1.8-2.4l2.7-2.1  l1.8,2.4L55.7,47.1z M53,43.5l-1.8-2.4l2.7-2.1l1.8,2.4L53,43.5z"></path></svg>
                        Claims payable procedure
                    </Menu.Item>
                }

                <Menu.Menu position='right'>
                    <Menu.Item
                        onClick={e => handleSubmitLogout()}
                    >
                        <Icon name='log out' />
                        Logout
                    </Menu.Item>
                </Menu.Menu>
            </div>
        </Menu>

    )
}
